

.topbar_sec{
    padding: 10px 0 0 0;
    font-size: 15px;  
    background-color: #03a831; 
}

.topbar_div p i{
    font-size: 23px;
    color: white;
    margin-right: 10px;
}

.topbar_div p {
    font-weight: 500;
    color: white;
}

.topbar_div p span{
    color: white;
    font-weight: 400;
}

.topbar_right{
    text-align: end;
}

.logo img{
    width: 110px; 
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 0;
    align-items: center;
}

.header_space{
    padding:5px 0px;
    box-shadow: 0px 0px 16px -8px grey;
}

.nav_header {
    padding: 0px;
}

.nav_ul ul li{
    padding: 0 10px;
}

.nav_ul ul li a{
    font-weight: bold;
    font-size: 18px;
}

.menu a::before{
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    transition: 0.5s transform ease;
    transform: scale3d(0,1,1);
    transform-origin: 0 50%;
  }

  .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #03a831;
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
}

.slide_bnr{
    width: 100%;
    height:450px;
}

.form_design{
    padding: 33px 30px;
    border: 1px solid #03a831;
}

.form-control {
    border: var(--bs-border-width) solid #dee2e6ba !important;
}

.btn_sbmit{
    color: white;
    background-color: #03a831;
}

.section-padding{
	width:1170px;
	margin: 0 auto;
	padding:80px 0;
}

.owl-item .item {
   transform: translate3d(0, 0, 0); /* DO NOT REMEMBER WHERE TU PUT THIS, SEARCH FOR 3D ACCELERATION */
  margin:  30px 0 /* OVERWRITE PLUGIN MARGIN */
 }

.screenshot_slider .owl-item .item img {
    -webkit-transition: 0.3s;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0.80);
    -ms-transform: scale(0.80);
    transform: scale(0.80);
}

.screenshot_slider .owl-item.center .item img {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}

.screenshot_slider .owl-nav {
    text-align: center;
}

.screenshot_slider .owl-nav button {
	font-size: 24px !important;
	margin: 10px;
	color: #033aff !important;
}

.slider_sec{
    padding-bottom:40px;
}

.about_img img{
    width: 100%;
    border-radius: 20px;
}

.about_tx h4{
    color: #03a831;
    font-size: 20px;
    font-weight: 500;
}

.about_tx h2{
    color: black;
    font-weight: 700;
    padding: 8px 0;
    font-size: 35px;
}

.about_tx p{
    font-size: 16px;
    text-align: justify;
    line-height: 1.4;
}

.service_title h2{
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 35px;
}

.service_sec {
    padding: 40px 0px 0px 0px;
}

.about_tx a{
    border-bottom: 2px solid #03a831;
    padding: 8px 9px;
    text-decoration: none;
    color: #03a831;
    border-radius: 8px;
}

.service_tx{
    text-align: center;
    height: 90px;
}

.service_tx h4{
   padding: 20px 0px 20px 0px;
}

.service_tx h4 a{
    font-size: 16px;
    color: black;
    text-decoration: none;
}

.service_icn{
    text-align: center;
    background-color: #d8edfa42;
    padding: 5px 5px;

}

.service_icn i{
    font-size: 50px;
    color: #03a831;
}

.box_item{
    box-shadow: 0px 0px 10px 3px #cfd1d373;
}

.box_item img{
    height: 200px;
    width:100%;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: black;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: black57;
    display: block;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    display: none;
}

.client_review img{
    text-align: center !important;
    width: 80px !important;
    height: 80px !important;
    border-radius: 163px;
    margin-bottom: 15px;
}

.client_review h5{
    padding-bottom: 5px;
    margin: 0;
}

.client_review{
    text-align: center !important;
    border: 2px solid #03a8316e;
    padding: 20px 20px;
    box-shadow: 0px 0px 6px 3px #c5d1d86e;
    background-color: white;
}

.owl-carousel .owl-item img {
    display: inline;
    width: 100%;
}

.blog_bx{
    border: 1px solid #a6b6c094;
    padding: 15px 20px;
    box-shadow: 0px 0px 10px 1px #c0d3e094;
    position: relative;
}

.blog_bx img{
    width: 100%;
    overflow: hidden;  
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    width: 100% !important;
    height: 200px !important;
}

.blog_bx img:hover{
    width: 100%;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    padding-left: 3px;
}

.blog_tx h5 {
    font-weight: 700;
    margin-top: 10px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;

}

.blog_tx{
    padding-top: 8px;
    height: 150px;
}

.blog_tx h5 a{
    text-decoration: none;
    font-size: 21px;
    color: black;
}

.blog_tx span{
    font-size: 15px;
    color: #ad040c; 
}



.blog_more{
    color: #0c0a86;
    text-decoration: none;
    border: 1px solid #11137a;
    padding: 7px 8px;
    font-size: 15px;
    border-radius: 5px;  
 }

 .blog_title{
    padding-bottom: 30px;
 }
 
 .footer_tx img{
    width: 145px;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: white;
 }

 .about_sec{
    background-color: #e4e4ed4a;
    padding: 40px 0;
 }

 .testimonial_sec{
    padding: 30px 0;
 }

 .blog_sec{
    padding: 40px 0;
 }

 .footer_sec{
    background-color: black;
    padding-top: 30px;
 }

 .footer_tx p{
    color: #e1dfdf;
    text-align: justify;
    padding: 10px 0;
 }
 
 .footer_tx ul{
    display: inline-flex;
    list-style-type: none;
    padding: 0;
 }
  
 .footer_tx ul li{
    margin: 0 7px;
 }
   
 .footer_tx ul li a i{
    color: #03a831;
    background-color: #e1dfdf;
    padding: 5px 7px;
    font-size: 18px;
    border-radius: 32px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 1.5;
 }

    
 .footer_tx ul li a i:hover{
    color: white;
    background-color: #9a1a1a;
    padding: 5px 7px;
    font-size: 18px;
    border-radius: 32px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 1.5;
 }

 .footer_service h4{
    color: #e1dfdf;
    padding-bottom: 15px;
 }

 
 .footer_service p a{
    text-decoration: none;
    color: #e1dfdf;
 }
 
 .footer_links h4{
    color: #e1dfdf;
    padding-bottom: 15px;
 }

 
 .footer_links p a{
    text-decoration: none;
    color: #e1dfdf;
 }
 
 .contact_footer h4{
    color: #e1dfdf;
    padding-bottom: 15px;
 }
 
 .contact_footer p a{
    text-decoration: none;
    color: #e1dfdf;
 }

  
 .contact_footer p a i{
    color: #03a831;
 }

 .copyright p{
    color: #e1dfdf;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 0px;
    margin-top: 15px;
    border-top: 1px solid #e1dfdf;
 }

 .doctx_sec{
    background-color: #e4e4ed4a;
    padding: 40px 0;
 }

 .doc_tx h4{
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 25px;
 }

 .doc_tx p{
    font-size: 18px;
    color: #918f8f;
 }
 
 .name_consult h4{
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 25px;
 }

 .consult_bx_tx{
    text-align: center;
    padding: 10px 10px;
 }

 .consult_bx img{
    width:100%;
    height: 150px;
 }

 .consult_bx_tx h6{
    color: #565555; 
    margin-bottom: 0px;    
    font-size: 16px;
 }
 
 .consult_bx_tx p{
    color: #03a831;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px; 
 }

 .consult_bx{    
    border: 1px solid #d1d1fb;
    /* margin: 10px 0; */
 }

 .consult_sec{
    padding: 40px 0;
 }

 .name_consult{
    padding-bottom: 15px;
}

.work_img i{
    font-size: 45px;
    color: white;
    padding-bottom: 5px;
}

.work_img{
    text-align: center;
    border: 1px solid #03a831;
    background-color: #03a831;
    padding: 15px 10px;
    border-radius: 10px;
    height: 150px;
    box-shadow: 0px 5px 7px 0px #09078636;
}

.work_img h6{
    margin-top: 15px;
    font-size: 16px;
    color: white;
}
.work_tx{
    text-align: center!important;
}

.work_tx h2 {
    color: white;
    font-weight: 700;
    padding: 8px 0;
    font-size: 35px;
}
.work_tx p {
    font-size: 18px;
    line-height: 2.0;
    padding-bottom: 20px;
    color:white;
}

.work_sec{
    padding: 40px 0;
    background-color: black;
}

.background{
    background-color: white;
}

.banner img{
    width: 100%;
}

.contact_pg h4{
    margin-bottom: 10px !important;
    font-size: 30px !important;
    color: #ad040c !important;
}

.contact_sc{
  padding: 40px 0;
}

.form_pg{
    border: 1px solid #8e433e;
}

.form_pg button{
    color: white !important;
    background-color: #ad040c !important;
}

.blog_detail_sec{    
  padding: 20px 0;
}

.blog_detail h3{
    font-size: 35px;
    font-weight: 700;
    margin: 13px 0;
}

.blog_detail p{
    font-size: 18px;
}

.blog_detail img{
width: 100%; 
}

.blog_date span{
    padding-right: 20px;
}

.blog_date a{
text-decoration:none;
}

.blog_date a i{
    color: #730709;
    font-size: 18px;
    margin-right:7px;
}

.blog_date a time{
    color: #730709;
    font-size: 18px;
}

.blog_social_icon span a i{
    font-size: 22px;
    border: 1px solid #730709;
    background-color: #730709;
    color: white;
    padding: 9px 0;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 15px;
    float: inline-end;  
}

.blog_tx_tg{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    color: #767575;
}

.video_sec{
    padding:40px 0px;
}

.blog_detail .bannerimg {
    height: 420px;
}


.banner_desc {
    height: 420px;
    width: 100%;
    background-image: url('/public/image/blogDetialsBG2.jpg');
}
.BannerDsceBox {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 420px;
}
.BannerDsceBox h2{
    color: #fff;
    font-size: 35px;
}



/* Contact Botton */
.wa-float-img-circle {
    width: 56px;
    height: 56px;
    bottom: 20px;
    right: 20px;
    top: 50%;
    border-radius: 100%;
    position: fixed;
    z-index: 99999;
    display: flex;
    transition: all .3s;
    align-items: center;
    justify-content: center;
    background: #25d366;
    color: transparent;
}

.call-float-img-circle {
    width: 56px;
    height: 56px;
    bottom: 100px;
    right: 20px;
    top: 40%;
    border-radius: 100%;
    position: fixed;
    z-index: 99999;
    display: flex;
    transition: all .3s;
    align-items: center;
    justify-content: center;
    background: #a80308;
    color: transparent;
}

.wa-float-img-circle:before {
    position: absolute;
    content: '';
    background-color: #25d366;
    width: 70px;
    height: 70px;
    bottom: -7px;
    right: -7px;
    border-radius: 100%;
    animation: wa-float-circle-fill-anim 2.3s infinite ease-in-out;
    transform-origin: center;
    opacity: .1;
}

.call-float-img-circle:before {
    position: absolute;
    content: '';
    background-color: #a80308;
    width: 70px;
    height: 70px;
    bottom: -7px;
    right: -7px;
    border-radius: 100%;
    animation: call-float-circle-fill-anim 2.3s infinite ease-in-out;
    transform-origin: center;
    opacity: .1;
}

.wa-float-img-circle i,
.call-float-img-circle i {
    color: #fff;
    font-size: 33px;
}

@keyframes wa-float-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
    }

    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }

    100% {
        transform: rotate(0) scale(.7) skew(1deg);
    }
}

@keyframes call-float-circle-fill-anim {
    0% {
        transform: rotate(0) scale(.7) skew(1deg);
    }

    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }

    100% {
        transform: rotate(0) scale(.7) skew(1deg);
    }
}


.service_tx a{
    font-size: 14px;
    color: black;
    margin-top: 9px;
    position: relative;
    top: 20px;
    list-style: none;
    text-decoration: none;
    line-height: 0.5;
}


.service_bg {
    background-image: url('/public/image/backgroundimage.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
